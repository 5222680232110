import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UnoService {
    private backendUrl = environment.BACKENDURL + 'api/uno';

    constructor(
        private httpClient: HttpClient
    ) { }

    async startGame() {
        return await this.httpClient.get<boolean>(this.backendUrl).toPromise();
    }

    async resetGame() {
        return await this.httpClient.delete<boolean>(this.backendUrl).toPromise();
    }

    async sendCards() {
        return await this.httpClient.put<boolean>(this.backendUrl, true).toPromise();
    }
}
