import { Component, Input, OnInit } from '@angular/core';
import { Card } from '../../models/card';
import { CardsService } from '../../services/cards.service';
import { SignalRService } from '../../services/signal-r.service';
import { UnoService } from '../../services/uno.service';

@Component({
  selector: 'app-cards-holder',
  templateUrl: './cards-holder.component.html',
  styleUrls: ['./cards-holder.component.scss']
})
export class CardsHolderComponent implements OnInit {
  @Input() username: string;

  cards: Array<Card> = [];

  normalCards: Array<Card> = [];
  Draw2Cards: Array<Card> = [];
  ReverseCards: Array<Card> = [];
  SkipCards: Array<Card> = [];
  WildCards: Array<Card> = [];
  WildDraw4Cards: Array<Card> = [];

  constructor(private signalRservice: SignalRService, private cardService: CardsService, private unoService: UnoService) { }

  ngOnInit(): void {
    window.addEventListener('resize', () => this.setHeight());

    this.signalRservice.addTransferGameListenerFunction(() => {
      this.cards = this.signalRservice.getMyCards(this.username);
      this.sortCards();
    });

    this.unoService.sendCards();
  }

  layCard(card: Card) {
    this.cardService.layCard(card);
  }

  sortCards() {
    this.normalCards = [];
    this.Draw2Cards = [];
    this.ReverseCards = [];
    this.SkipCards = [];
    this.WildCards = [];
    this.WildDraw4Cards = [];

    this.cards.forEach(card => {
      if (card.draw2) {
        this.Draw2Cards.push(card);
      } else if (card.reverse) {
        this.ReverseCards.push(card);
      } else if (card.skip) {
        this.SkipCards.push(card);
      } else if (card.wild) {
        this.WildCards.push(card);
      } else if (card.wildDraw4) {
        this.WildDraw4Cards.push(card);
      } else if (card.number || card.number === 0) {
        this.normalCards.push(card);
      }
    });

    setTimeout(() => {
      this.setHeight();
    }, 100);
  }

  setHeight() {
    const svgs: Array<SVGElement> = Array.prototype.slice.call(document.getElementsByTagName('svg'));
    svgs.forEach(svg => {
      svg.style.height = '0px';
    });
    const holder: HTMLElement = Array.prototype.slice.call(document.getElementsByClassName('holder'))[0];
    if (holder) {
      const height = holder.clientHeight;
      svgs.forEach(svg => {
        svg.style.height = height.toString() + 'px';
      });
    } else {
      Array.prototype.slice.call(document.getElementsByClassName('message-holder'))[0].style.width
        = document.documentElement.clientWidth.toString() + 'px';
    }
  }
}
