import { Card } from './card';
import { UserCardList } from './userCardList';

export class Game {
    CurrentPlayer: string;
    CurrentCard: Card;
    GameIsRunning: boolean;

    UsersAndCards: Array<UserCardList> = [];
}
