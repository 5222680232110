import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsernameService {
  private backendUrl = environment.BACKENDURL + 'api/username/';

  constructor(
    private httpClient: HttpClient
  ) { }

  async isUserNameAvailable(username: string) {
    return await this.httpClient.get<boolean>(this.backendUrl + username).toPromise();
  }
}
