import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalRService } from 'src/app/shared/services/signal-r.service';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit {
  username: string;
  start = true;
  portrait = false;

  constructor(private route: ActivatedRoute, private signalRservice: SignalRService) { }

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');

    this.signalRservice.startConnection();
    this.signalRservice.addTransferGameListener();

    window.addEventListener('resize', () => this.setCardHolderHeight());

    setTimeout(() => {
      this.styleStartbutton();
    }, 100);
  }

  fullscreen() {
    document.body.requestFullscreen();
    this.start = false;
    this.setCardHolderHeight();
  }

  styleStartbutton() {
    const button: HTMLElement = Array.prototype.slice.call(document.getElementsByClassName('start'))[0];
    if (button) {
      const width: number = (document.documentElement.clientWidth - button.clientWidth) / 2;
      button.style.marginLeft = width.toString() + 'px';
    }
  }

  checkPortrait() {
    const height = document.documentElement.clientHeight;
    const width = document.documentElement.clientWidth;

    if (height > width) {
      this.portrait = true;
    } else {
      this.portrait = false;
    }
  }

  setCardHolderHeight() {
    this.styleStartbutton();
    this.checkPortrait();

    setTimeout(() => {
      const cardHolder: HTMLElement = Array.prototype.slice.call(document.getElementsByTagName('app-cards-holder'))[0];

      if (cardHolder) {
        const height = document.documentElement.clientHeight * 0.8 < 500 ? document.documentElement.clientHeight * 0.8 : 500;
        cardHolder.style.height = height.toString() + 'px';
      }
    }, 100);
  }
}
