import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PlayComponent } from './pages/play/play.component';
import { CardsHolderComponent } from './shared/components/cards-holder/cards-holder.component';
import { PortraitModeComponent } from './shared/components/portrait-mode/portrait-mode.component';
import { CurrentCardComponent } from './pages/current-card/current-card.component';


@NgModule({
  declarations: [
    AppComponent,
    PlayComponent,
    CardsHolderComponent,
    LoginComponent,
    PortraitModeComponent,
    CurrentCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
