import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CurrentCardComponent } from './pages/current-card/current-card.component';
import { LoginComponent } from './pages/login/login.component';
import { PlayComponent } from './pages/play/play.component';


const routes: Routes = [
  { path: 'play/:username', component: PlayComponent },
  { path: 'login', component: LoginComponent },
  { path: 'current-card', component: CurrentCardComponent },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
