<h1>Bitte gib deinen Benutzernamen ein</h1>
<h3 *ngIf="usernameExists">Dieser Benutzername existiert bereits!</h3>
<h3 *ngIf="showMinlength">Der Benutzername muss mindestens 3 Zeichen lang sein!</h3>
<input type="text" maxlength="8" placeholder="Benutzername" [(ngModel)]="username">
<div class="buttons">
    <button [disabled]="username.length === 0" (click)="usernameChanged()">Lokal
        spielen</button>
    <button [disabled]="username.length === 0">Auf distanz spielen</button>
</div>
<div class="buttons">
    <button [routerLink]="['/play/' + username]">Weiter Spielen</button>
    <button [routerLink]="['/current-card']">Aktuelle karte</button>
</div>
<div class="buttons">
    <button (click)="start()">Start</button>
    <button (click)="reset()">Reset</button>
</div>