import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Card } from '../models/card';

@Injectable({
    providedIn: 'root'
})
export class CardsService {
    private backendUrl = environment.BACKENDURL + 'api/cards';

    constructor(
        private httpClient: HttpClient
    ) { }

    async takeCard() {
        return await this.httpClient.get<boolean>(this.backendUrl).toPromise();
    }

    async layCard(card: Card) {
        return await this.httpClient.put<boolean>(this.backendUrl + '/' + card.id.toString(), true).toPromise();
    }
}
