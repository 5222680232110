<div class="holder" *ngIf="card">
    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.number">
        <rect style="fill: rgb(71, 255, 105); stroke: rgb(255, 255, 255); stroke-width: 3px;" x="1.824" y="1.547"
            width="60" height="90" rx="3" ry="3" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
        <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
            transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.012016, 54.623531)" />
        <text
            style="fill: rgb(255, 55, 55); font-family: Helvetica, Arial, sans-serif; font-size: 35px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="22.419" y="59.884" [class.red]="card.color === 'red'" [class.green]="card.color === 'green'"
            [class.blue]="card.color === 'blue'" [class.yellow]="card.color === 'yellow'">{{card.number}}</text>
        <text
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="5.279" y="19.536">{{card.number}}</text>
        <text transform="matrix(-1, 0, 0, -1, 0, 0)"
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-dashoffset: 0.5px; stroke-width: 0.5px; white-space: pre;"
            x="-57.825" y="-73.379">{{card.number}}</text>
    </svg>

    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.draw2">
        <defs>
            <filter id="drop-shadow-filter-0" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="spread-ctrl" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-1" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="feFuncA-1" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <rect style="fill: rgb(71, 255, 105); stroke: rgb(255, 255, 255); stroke-width: 3px;" x="1.824" y="1.547"
            width="60" height="90" rx="3" ry="3" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
        <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
            transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.012016, 54.623531)" />
        <text
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="4.82" y="19.611">+2</text>
        <text
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="-58.546" y="-73.737" transform="matrix(-1, 0, 0, -1, 0, 0)">+2</text>
        <rect
            style="fill: rgb(71, 255, 105); paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#drop-shadow-filter-0);"
            x="29.143" y="35.058" width="11.7" height="17.5" rx="1.5" ry="1.5" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
        <rect
            style="fill: rgb(71, 255, 105); paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#filter-1);"
            x="24.408" y="42.306" width="11.697" height="17.545" rx="1.5" ry="1.5" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
    </svg>

    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.skip">
        <rect style="fill: rgb(71, 255, 105); stroke: rgb(255, 255, 255); stroke-width: 3px;" x="1.977" y="1.547"
            width="60" height="90" rx="3" ry="3" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
        <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
            transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.012016, 54.623531)" />
        <g transform="matrix(1, 0, 0, 1, -0.307215, 0.497146)">
            <path
                d="M 317 277 m -14 0 a 14 14 0 1 0 28 0 a 14 14 0 1 0 -28 0 Z M 317 277 m -10 0 a 10 10 0 0 1 20 0 a 10 10 0 0 1 -20 0 Z"
                style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(-0.686624, 0.727013, -0.727013, -0.686624, 451.650442, 7.425244)"
                bx:shape="ring 317 277 10 10 14 14 1@3d160c9a" [class.red]="card.color === 'red'"
                [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
                [class.yellow]="card.color === 'yellow'" />
            <rect x="11.404" y="21.804" width="19.042" height="4"
                style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 0.991969, 45.647575)"
                [class.red]="card.color === 'red'" [class.green]="card.color === 'green'"
                [class.blue]="card.color === 'blue'" [class.yellow]="card.color === 'yellow'" />
            <rect x="12.957" y="19.079" width="21.635" height="3.5"
                style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0px;"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 1.018168, 49.834984)"
                [class.red]="card.color === 'red'" [class.green]="card.color === 'green'"
                [class.blue]="card.color === 'blue'" [class.yellow]="card.color === 'yellow'" />
        </g>
        <g transform="matrix(0.505282, 0, 0, 0.505282, -3.574879, -11.6234)">
            <path
                d="M 317 277 m -14 0 a 14 14 0 1 0 28 0 a 14 14 0 1 0 -28 0 Z M 317 277 m -10 0 a 10 10 0 0 1 20 0 a 10 10 0 0 1 -20 0 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.686624, 0.727013, -0.727013, -0.686624, 451.650442, 7.425244)"
                bx:shape="ring 317 277 10 10 14 14 1@3d160c9a" />
            <rect x="11.404" y="21.804" width="19.042" height="4"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 0.991969, 45.647575)" />
            <rect x="12.957" y="19.079" width="21.635" height="3.5"
                style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(255, 255, 255);"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 1.018168, 49.834984)" />
        </g>
        <g transform="matrix(0.505282, 0, 0, 0.505282, 34.60928, 56.680531)">
            <path
                d="M 317 277 m -14 0 a 14 14 0 1 0 28 0 a 14 14 0 1 0 -28 0 Z M 317 277 m -10 0 a 10 10 0 0 1 20 0 a 10 10 0 0 1 -20 0 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.686624, 0.727013, -0.727013, -0.686624, 451.650442, 7.425244)"
                bx:shape="ring 317 277 10 10 14 14 1@3d160c9a" />
            <rect x="11.404" y="21.804" width="19.042" height="4"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 0.991969, 45.647575)" />
            <rect x="12.957" y="19.079" width="21.635" height="3.5"
                style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(255, 255, 255);"
                transform="matrix(0.707107, -0.707106, 0.707106, 0.707107, 1.018168, 49.834984)" />
        </g>
    </svg>

    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.reverse">
        <rect style="fill: rgb(71, 255, 105); stroke: rgb(255, 255, 255); stroke-width: 3px;" x="1.977" y="1.547"
            width="60" height="90" rx="3" ry="3" [class.red]="card.color === 'red'"
            [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
            [class.yellow]="card.color === 'yellow'" />
        <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
            transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.012016, 54.623531)" />
        <g transform="matrix(1.063294, 0, 0, 1.063294, -0.721201, -1.578799)">
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(0.629305, 0.777158, -0.777158, 0.629305, 4.157768, -67.021576)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" [class.red]="card.color === 'red'"
                [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
                [class.yellow]="card.color === 'yellow'" />
            <path style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(0.777145, -0.629321, 0.629321, 0.777145, -12.231865, 22.634665)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" [class.red]="card.color === 'red'"
                [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
                [class.yellow]="card.color === 'yellow'" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(71, 255, 105);"
                transform="matrix(0.777146, -0.62932, 0.62932, 0.777146, -17.816196, 28.977453)"
                [class.red]="card.color === 'red'" [class.green]="card.color === 'green'"
                [class.blue]="card.color === 'blue'" [class.yellow]="card.color === 'yellow'" />
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(-0.629305, -0.777158, 0.777158, -0.629305, 57.691509, 160.178116)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" [class.red]="card.color === 'red'"
                [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
                [class.yellow]="card.color === 'yellow'" />
            <path style="fill: rgb(71, 255, 105); stroke: rgb(0, 0, 0); stroke-width: 0.5px;"
                transform="matrix(-0.777145, 0.629321, -0.629321, -0.777145, 74.081139, 70.521881)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" [class.red]="card.color === 'red'"
                [class.green]="card.color === 'green'" [class.blue]="card.color === 'blue'"
                [class.yellow]="card.color === 'yellow'" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(71, 255, 105);"
                transform="matrix(-0.777146, 0.62932, -0.62932, -0.777146, 79.665474, 64.179092)"
                [class.red]="card.color === 'red'" [class.green]="card.color === 'green'"
                [class.blue]="card.color === 'blue'" [class.yellow]="card.color === 'yellow'" />
        </g>
        <g transform="matrix(0.679437, 0, 0, 0.591105, -7.505757, -14.528223)">
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.629305, 0.777158, -0.777158, 0.629305, 4.157768, -67.021576)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" />
            <path style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.777145, -0.629321, 0.629321, 0.777145, -12.231865, 22.634665)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(255, 255, 255);"
                transform="matrix(0.777146, -0.62932, 0.62932, 0.777146, -17.816196, 28.977453)" />
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.629305, -0.777158, 0.777158, -0.629305, 57.691509, 160.178116)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" />
            <path style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.777145, 0.629321, -0.629321, -0.777145, 74.081139, 70.521881)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(255, 255, 255);"
                transform="matrix(-0.777146, 0.62932, -0.62932, -0.777146, 79.665474, 64.179092)" />
        </g>
        <g transform="matrix(0.679437, 0, 0, 0.591105, 29.355843, 52.385944)">
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.629305, 0.777158, -0.777158, 0.629305, 4.157768, -67.021576)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" />
            <path style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(0.777145, -0.629321, 0.629321, 0.777145, -12.231865, 22.634665)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(255, 255, 255);"
                transform="matrix(0.777146, -0.62932, 0.62932, 0.777146, -17.816196, 28.977453)" />
            <path d="M 102.18 33.004 L 106.68 41.004 L 97.68 41.004 L 102.18 33.004 Z"
                style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.629305, -0.777158, 0.777158, -0.629305, 57.691509, 160.178116)"
                bx:shape="triangle 97.68 33.004 9 8 0.5 0 1@6994da4b" />
            <path style="stroke: rgb(0, 0, 0); stroke-width: 0.5px; fill: rgb(255, 255, 255);"
                transform="matrix(-0.777145, 0.629321, -0.629321, -0.777145, 74.081139, 70.521881)"
                d="M 14.967 40.344 H 28.087 V 45.536 H 12.367 V 42.94 A 2.6 2.596 0 0 1 14.967 40.344 Z"
                bx:shape="rect 12.367 40.344 15.72 5.192 2.6 0 0 0 1@9cc258f0" />
            <rect x="34.521" y="39.178" width="4.316" height="4.695" style="fill: rgb(255, 255, 255);"
                transform="matrix(-0.777146, 0.62932, -0.62932, -0.777146, 79.665474, 64.179092)" />
        </g>
    </svg>

    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.wild">
        <rect style="stroke: rgb(255, 255, 255); stroke-width: 3px;" x="2.118" y="1.547" width="60" height="90" rx="3"
            ry="3" />
        <g>
            <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
                transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.011975, 54.623529)" />
            <path d="M 32.033 47.976 L 13.385 69.945 C 30.988 72.747 52.669 55.858 56.553 47.222 L 32.033 47.976 Z"
                id="path-1" style="fill: rgb(0, 170, 0); fill-opacity: 1; fill-rule: evenodd; stroke: none;"
                transform="matrix(1, -0.000054, 0.000054, 1, -0.003172, 0.001888)" class="green" />
            <path d="M 26.048 26.767 L 7.042 48.219 C 24.937 52.34 45.974 35.945 51.016 25.877 L 26.048 26.767 Z"
                id="path-2" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(255, 0, 0);"
                transform="matrix(-1, 0.000054, -0.000054, -1, 58.060017, 74.745558)" class="red" />
            <path d="M 6.97 44.798 L 7.794 69.825 C 20.935 77.897 27.751 69.213 28.942 63.464 L 6.97 44.798 Z"
                id="path-3" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(221, 255, 0);"
                transform="matrix(0.000054, 1, -1, 0.000054, 76.904205, 40.989991)" class="yellow" />
            <path
                d="M 35.242 23.306 L 36.009 47.835 C 49.049 55.798 55.42 47.919 56.706 42.271 C 56.706 42.271 35.439 23.306 35.242 23.306 Z"
                id="path-4" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(21, 0, 255);"
                transform="matrix(-0.000054, -1, 1, -0.000054, 8.717061, 83.235435)" class="blue" />
        </g>
        <g transform="matrix(0.238131, 0, 0, 0.304422, 4.764286, -1.920861)" style="">
            <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
                transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.011975, 54.623529)" />
            <path d="M 32.033 47.976 L 13.385 69.945 C 30.988 72.747 52.669 55.858 56.553 47.222 L 32.033 47.976 Z"
                id="path-5" style="fill: rgb(0, 170, 0); fill-opacity: 1; fill-rule: evenodd; stroke: none;"
                transform="matrix(1, -0.000054, 0.000054, 1, -0.003172, 0.001888)" class="green" />
            <path d="M 26.048 26.767 L 7.042 48.219 C 24.937 52.34 45.974 35.945 51.016 25.877 L 26.048 26.767 Z"
                id="path-6" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(255, 0, 0);"
                transform="matrix(-1, 0.000054, -0.000054, -1, 58.060017, 74.745558)" class="red" />
            <path d="M 6.97 44.798 L 7.794 69.825 C 20.935 77.897 27.751 69.213 28.942 63.464 L 6.97 44.798 Z"
                id="path-7" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(221, 255, 0);"
                transform="matrix(0.000054, 1, -1, 0.000054, 76.904205, 40.989991)" class="yellow" />
            <path
                d="M 35.242 23.306 L 36.009 47.835 C 49.049 55.798 55.42 47.919 56.706 42.271 C 56.706 42.271 35.439 23.306 35.242 23.306 Z"
                id="path-8" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(21, 0, 255);"
                transform="matrix(-0.000054, -1, 1, -0.000054, 8.717061, 83.235435)" class="blue" />
        </g>
        <g transform="matrix(0.238131, 0, 0, 0.304422, 44.075996, 65.349167)" style="">
            <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
                transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.011975, 54.623529)" />
            <path d="M 32.033 47.976 L 13.385 69.945 C 30.988 72.747 52.669 55.858 56.553 47.222 L 32.033 47.976 Z"
                id="path-9" style="fill: rgb(0, 170, 0); fill-opacity: 1; fill-rule: evenodd; stroke: none;"
                transform="matrix(1, -0.000054, 0.000054, 1, -0.003172, 0.001888)" class="green" />
            <path d="M 26.048 26.767 L 7.042 48.219 C 24.937 52.34 45.974 35.945 51.016 25.877 L 26.048 26.767 Z"
                id="path-10" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(255, 0, 0);"
                transform="matrix(-1, 0.000054, -0.000054, -1, 58.060017, 74.745558)" class="red" />
            <path d="M 6.97 44.798 L 7.794 69.825 C 20.935 77.897 27.751 69.213 28.942 63.464 L 6.97 44.798 Z"
                id="path-11" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(221, 255, 0);"
                transform="matrix(0.000054, 1, -1, 0.000054, 76.904205, 40.989991)" class="yellow" />
            <path
                d="M 35.242 23.306 L 36.009 47.835 C 49.049 55.798 55.42 47.919 56.706 42.271 C 56.706 42.271 35.439 23.306 35.242 23.306 Z"
                id="path-12" style="fill-opacity: 1; fill-rule: evenodd; stroke: none; fill: rgb(21, 0, 255);"
                transform="matrix(-0.000054, -1, 1, -0.000054, 8.717061, 83.235435)" class="blue" />
        </g>
    </svg>

    <svg viewBox="0 0 64 94" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" *ngIf="card.wildDraw4">
        <defs>
            <filter id="filter-3" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="feFuncA-3" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-2" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="feFuncA-2" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-4" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="feFuncA-4" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-5" x="-500%" y="-500%" width="1000%" height="1000%"
                bx:preset="drop-shadow 1 -1 1 0 0.83 rgba(0,0,0,1)">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
                <feOffset dx="-1" dy="1" />
                <feComponentTransfer result="offsetblur">
                    <feFuncA id="feFuncA-5" type="linear" slope="1.66" />
                </feComponentTransfer>
                <feFlood flood-color="rgba(0,0,0,1)" x="10" y="10" />
                <feComposite in2="offsetblur" operator="in" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <rect style="stroke: rgb(255, 255, 255); stroke-width: 3px;" x="2.118" y="1.547" width="60" height="90" rx="3"
            ry="3" />
        <ellipse style="fill: rgb(255, 255, 255);" cx="91.753" cy="49.067" rx="32.5" ry="18.5"
            transform="matrix(0.849893, -0.526956, 0.526956, 0.849893, -72.01194, 54.623524)" />
        <text
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="5.039" y="19.417">+4</text>
        <text
            style="fill: rgb(255, 255, 255); font-family: Helvetica, Arial, sans-serif; font-size: 20px; stroke: rgb(0, 0, 0); stroke-width: 0.5px; white-space: pre;"
            x="-58.9" y="-72.883" transform="matrix(-1, 0, 0, -1, 0, 0)">+4</text>
        <rect
            style="paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#filter-3); fill: rgb(0, 160, 27);"
            x="17.845" y="42.963" width="11.697" height="17.545" rx="1.5" ry="1.5" class="green" />
        <rect
            style="paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#filter-2); fill: rgb(25, 0, 255);"
            x="23.167" y="35.351" width="11.7" height="17.5" rx="1.5" ry="1.5" class="blue" />
        <rect
            style="paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#filter-4); fill: rgb(242, 255, 0);"
            x="37.324" y="29.402" width="11.7" height="17.5" rx="1.5" ry="1.5" class="yellow" />
        <rect
            style="paint-order: fill; stroke-width: 0.5px; stroke: rgb(0, 0, 0); filter: url(#filter-5); fill: rgb(255, 0, 0);"
            x="32.123" y="40.293" width="11.697" height="17.545" rx="1.5" ry="1.5" class="red" />
    </svg>
</div>