import { Component, OnInit } from '@angular/core';
import { Card } from 'src/app/shared/models/card';
import { SignalRService } from 'src/app/shared/services/signal-r.service';
import { UnoService } from 'src/app/shared/services/uno.service';

@Component({
  selector: 'app-current-card',
  templateUrl: './current-card.component.html',
  styleUrls: ['./current-card.component.scss']
})
export class CurrentCardComponent implements OnInit {
  card: Card;

  constructor(private signalRservice: SignalRService, private unoService: UnoService) { }

  async ngOnInit() {
    this.signalRservice.startConnection();
    this.signalRservice.addTransferGameListener();

    this.signalRservice.addTransferGameListenerFunction(() => {
      this.card = this.signalRservice.game.CurrentCard;
      console.log(this.card);
    });

    setTimeout(() => {
      this.unoService.sendCards();
    }, 200);
  }

}
