import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { Card } from '../models/card';
import { Game } from '../models/game';
import { UserCardList } from '../models/userCardList';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public game = new Game();

  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.BACKENDURL + 'unohub')
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  public addTransferGameListener = () => {
    this.hubConnection.on('transfergameviewmodel',
      (currentPlayer: string, currentCard: Card, gameIsRunning: boolean, usernames: Array<string>, cards: Array<Array<Card>>) => {
        this.game = new Game();

        this.game.CurrentPlayer = currentPlayer;
        this.game.CurrentCard = currentCard;
        this.game.GameIsRunning = gameIsRunning;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < usernames.length; i++) {
          let userCardList = new UserCardList();

          userCardList.Username = usernames[i];
          userCardList.Cards.push(...cards[i]);

          this.game.UsersAndCards.push(userCardList);
        }
      });
  }

  public addTransferGameListenerFunction = (onNewData: () => any) => {
    this.hubConnection.on('transfergameviewmodel', () => onNewData());
  }

  public getMyCards(username: string): Array<Card> {
    return this.game.UsersAndCards.filter(x => x.Username === username).map(x => x.Cards)[0];
  }
}

