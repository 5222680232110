import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portrait-mode',
  templateUrl: './portrait-mode.component.html',
  styleUrls: ['./portrait-mode.component.scss']
})
export class PortraitModeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const main: HTMLElement = Array.prototype.slice.call(document.getElementsByTagName('main'))[0];
    main.style.width = document.documentElement.clientWidth.toString() + 'px';
  }

}
