import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnoService } from 'src/app/shared/services/uno.service';
import { UsernameService } from 'src/app/shared/services/username.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  username = '';
  usernameExists = false;
  showMinlength = false;

  constructor(private usernameService: UsernameService, private route: ActivatedRoute,
    private router: Router, private unoService: UnoService) { }

  async usernameChanged() {
    if (this.username.length < 3) {
      this.showMinlength = true;
      this.usernameExists = false;
    } else {
      this.showMinlength = false;
      if (await this.usernameService.isUserNameAvailable(this.username)) {
        this.router.navigate(['/play', this.username], { relativeTo: this.route });
      } else {
        this.usernameExists = true;
      }
    }
  }

  start() {
    this.unoService.startGame();
  }

  reset() {
    this.unoService.resetGame();
  }
}
